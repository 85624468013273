import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'

import Home from '../views/Home.vue'


const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/404', name: 'PageNotFound', component: () => import(/* webpackChunkName: "pagenotfoudn" */ '../views/PageNotFound.vue') },
  { path: '/contact-us', name: 'ContactUs', component: () => import(/* webpackChunkName: "contactus" */ '../views/ContactUs.vue') },
  { path: '/signup', name: 'SignUp', component: () => import(/* webpackChunkName: "signup" */ '../views/auth/SignUp.vue') },
  { path: '/signin', name: 'SignIn', component: () => import(/* webpackChunkName: "signin" */ '../views/auth/SignIn.vue') },
  { path: '/welcome', name: 'Welcome', component: () => import(/* webpackChunkName: "welcome" */ '../views/auth/Welcome.vue') },
  { path: '/user', name: 'MyAccount', component: () => import(/* webpackChunkName: "userhomepage" */ '../views/user/UserAccount.vue'), meta: {requireLogin: true} },
  { path: '/user/orders', name: 'MyOrders', component: () => import(/* webpackChunkName: "myorders" */ '../views/user/UserOrders'), meta: {requireLogin: true} },
  { path: '/user/orders/:id', name: 'OrderDetails', component: () => import(/* webpackChunkName: "orderdetails" */ '../views/user/UserOrderDetails'), meta: {requireLogin: true} },
  { path: '/dashboard/orders', name: 'OrderManagement', component: () => import(/* webpackChunkName: "order-management" */ '../views/dashboard/orders/AdminOrders'), meta: {requireLogin: true} },
  { path: '/dashboard/orders/:id', name: 'AdminOrderUpdate', component: () => import(/* webpackChunkName: "admin-order-update" */ '../views/dashboard/orders/AdminOrderUpdate'), meta: {requireLogin: true} },
  { path: '/dashboard/contacts', name: 'ContactManagement', component: () => import(/* webpackChunkName: "contact-management" */ '../views/dashboard/contacts/ContactManagement'), meta: {requireLogin: true} },
  { path: '/dashboard/callbackrequests', name: 'CallBackRequestManagement', component: () => import(/* webpackChunkName: "callbackrequest-management" */ '../views/dashboard/callbackrequests/CallBackRequestManagement'), meta: {requireLogin: true} },
  { path: '/dashboard/contact-us', name: 'ContactUsManagement', component: () => import(/* webpackChunkName: "contact-us-management" */ '../views/dashboard/contactus/ContactUsManagement'), meta: {requireLogin: true} },
  { path: '/search/:query', name: 'Search', component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue') },
  { path: '/company/:page', name: 'Company', component: () => import(/* webpackChunkName: "company" */ '../views/Company.vue') },
  { path: '/cart', name: 'Cart', component: () => import(/* webpackChunkName: "cart" */ '../views/cart/Cart.vue') },
  { path: '/cart/success', name: 'Success', component: () => import(/* webpackChunkName: "success" */ '../views/cart/Success.vue') },
  { path: '/cart/checkout', name: 'Checkout', component: () => import(/* webpackChunkName: "checkout" */ '../views/cart/Checkout.vue')  },
  { path: '/shop', name: 'Shop', component: () => import(/* webpackChunkName: "shop" */ '../views/catalog/Shop')  },
  { path: '/shop/:category_slug/:subcategory_slug/:product_slug', name: 'Product', component: () => import(/* webpackChunkName: "product" */ '../views/catalog/Product.vue') },
  { path: '/shop/:category_slug/:subcategory_slug', name: 'SubCategory', component: () => import(/* webpackChunkName: "product" */ '../views/catalog/SubCategory.vue') },
  { path: '/shop/:category_slug', name: 'Category', component: () => import(/* webpackChunkName: "category" */ '../views/catalog/Category.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.auth.isAuthenticated) {
    next({ name: 'SignIn', query: { to: to.path } });
  } else {
    next()
  }
})

export default router
