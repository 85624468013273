import axios from 'axios'

const state =() => ({
    settings: [],
    notification_off: false
})

const mutations = {
    SAVE_SETTINGS(state, settings) {
        state.settings = settings
    },
    TOGGLE_NOTIFICATION(state, status) {
      state.notification_off = status
    }
}

const actions = {
    getSettings({commit}) {
        axios.get('/api/v1/site-settings/')
            .then(response => {
                commit('SAVE_SETTINGS', response.data[0])
            })
    },
    turnOffNotification() {

    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}

