import axios from 'axios'

const state =() => ({
    banners: [],
})

const mutations = {
    SAVE_BANNERS(state, banners) {
        state.banners = banners
    }
}

const actions = {
    getBanners({commit}) {
        axios.get('/api/v1/banners/?is_active=true&ordering=priority')
            .then(response => {
                commit('SAVE_BANNERS', response.data)
            })
    }
}

// const getters = {
//     getSubcategories: (state) => (category) => {
//         return state.categories.filter(c => c.slug === category)
//     }
// }

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}