const state = () => ({
    cart: {
        items: [],
    },
})
const mutations = {
    initializeCart(state) {
        if (localStorage.getItem('cart')) {
            state.cart = JSON.parse(localStorage.getItem('cart'))
        } else {
            localStorage.setItem('cart', JSON.stringify(state.cart))
        }
    },
    addToCart(state, item) {
        const exists = state.cart.items.filter(i => i.product.id === item.product.id)
        if (exists.length) {
            exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
        } else {
            state.cart.items.push(item)
        }
        localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    clearCart(state) {
        state.cart = {items: []}
        localStorage.setItem('cart', JSON.stringify(state.cart))
    },
}
const getters = {
    cartItems (state) {
        return state.cart
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}
