import axios from 'axios'

const state =() => ({
    categories: [],
})

const mutations = {
    SAVE_CATEGORIES(state, categories) {
        state.categories = categories
    }
}

const actions = {
    getCategories({commit}) {
        axios.get('/api/v1/products/categories/?is_parent=true&ordering=name&is_active=true')
            .then(response => {
                commit('SAVE_CATEGORIES', response.data)
            })
    }
}

const getters = {
    getSubcategories: (state) => (category) => {
        return state.categories.filter(c => c.slug === category)
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}