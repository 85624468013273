import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from './i18n'
import TawkMessenger from '@tawk.to/tawk-messenger-vue-3'
import VueLazyload from 'vue-lazyload'


require('@/assets/main.scss')

axios.defaults.baseURL = 'https://kanstov.uz'
// axios.defaults.baseURL = 'http://localhost:8000'

const app = createApp(App)

app
    .use(i18n)
    .use(store)
    .use(router, axios)
    .use(VueLazyload)
    .use(TawkMessenger, {
        propertyId : '62fa455a37898912e96318a5',
        widgetId : '1gagptqse'
    })
    .mount('#app')