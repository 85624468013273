<template>
  <footer class="footer mt-6 has-background-black">
    <div class="container">
      <div class="columns">
        <div class="column is-5">
          <router-link to="/" class="heading has-text-white">
            KANSTOV.UZ - канцтовары, хозтовары, <br />электротехника и бытовая техника.
          </router-link>
          <ul class="mt-5">
            <li class="heading has-text-white">Телефон: <a :href="'tel:' + settings.main_phone_number">{{ settings.main_phone_number }} </a> </li>
            <li class="heading has-text-white">Телеграм: <a target="_blank" :href="`https://t.me/${settings.main_telegram_username}`">{{ settings.main_telegram_username }}</a> </li>
          </ul>
        </div>
        <div class="column is-4">
          <ul v-for="category in categories">
            <li>
              <router-link :to="'/shop' + category.get_absolute_url" class="heading has-text-white">{{ category.name }}</router-link>
            </li>
          </ul>
        </div>
        <div class="column is-3">
          <ul>
            <li>
              <router-link to="/" class="heading has-text-white">Главная</router-link>
            </li>
            <li>
              <router-link to="/contact-us" class="heading has-text-white">Связаться с нами</router-link>
            </li>
            <template v-for="page in pages" :key="page.id">
              <li><router-link :to="`/company/${page.slug}`" class="heading has-text-white">{{ page.menu_title }}</router-link></li>
            </template>
          </ul>
        </div>
      </div>
      <p class="subtitle mt-6 is-7 has-text-centered has-text-white">Kanstov.UZ. Все права защищены. 2018-2022</p>
    </div>
  </footer>
</template>
<script>
import {mapState} from 'vuex'

export default {
  name: 'FooterComponent',
  computed: {
    ...mapState({
      settings: state => state.site_settings.settings,
      categories: state => state.categories.categories,
      pages: state => state.pages.pages
    }),
  }
}
</script>
<style scoped>
</style>