<template>
  <div class="level is-mobile has-background-black">
    <div class="level-item ">

    </div>
    <div class="level-item ">
      <div>
        <p class="heading">
          <a class="has-text-white mr-6" @click="callback_modal=true">
            <span class="icon"><i class="fas fa-phone"></i></span>
            Перезвоните мне
          </a>
          <a class="has-text-white mr-6" :href="'tel:' + settings.main_phone_number">
            <span class="icon"><i class="fas fa-phone"></i></span>
            {{ settings.main_phone_number }}
          </a>
          <a class="has-text-white mr-6" target="_blank" :href="'https://t.me/' + settings.main_telegram_username">
            <span class="icon"><i class="fas fa-paper-plane"></i></span>
            Telegram
          </a>
          <router-link class="has-text-white is-hidden-mobile" to="/contact-us">
            <span class="icon"><i class="fas fa-paper-plane"></i></span>
            Связаться с нами
          </router-link>
        </p>
      </div>
    </div>
    <div class="modal" :class="{'is-active': callback_modal}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Заказать бесплатную консультацию</p>
          <button class="delete" aria-label="close" @click="callback_modal=false"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Ваш номер телефона (обязательно)</label>
            <div class="control">
              <input class="input" type="text" placeholder="93 123 45 67" v-model="contact_info.phone">
            </div>
          </div>
          <div class="field">
            <label class="label label-not-required">Ваше имя (не обязательно)</label>
            <div class="control">
              <input class="input" type="text" placeholder="Ваше имя" v-model="contact_info.name">
            </div>
          </div>
          <div class="field">
            <label class="label label-not-required">Комментарии (не обязательно)</label>
            <div class="control">
              <textarea class="textarea" v-model="comments"
                        placeholder="Если у вас есть вопросы или комментарии, можете их оставить здесь"></textarea>
            </div>
          </div>
          <div class="field">
            <div class="notification is-danger mt-4" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
              class="button is-primary"
              :disabled="isButtonDisabled"
              @click="submitForm"
          >Отправить
          </button>
          <button class="button" @click="callback_modal=false">Отменить</button>
        </footer>
      </div>
    </div>
    <div class="modal" :class="{'is-active': thank_you_modal}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Мы получили вашу заявку</p>
          <button class="delete" aria-label="close" @click="thank_you_modal=false"></button>
        </header>
        <section class="modal-card-body">
          Наши операторы свяжутся с Вами в ближайшее время.
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="thank_you_modal=false">Закрыть</button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import axios from "axios";

export default {
  name: 'TopBar',
  data() {
    return {
      callback_modal: false,
      thank_you_modal: false,
      contact_info: {
        name: localStorage.getItem('personal_data_name'),
        phone: localStorage.getItem('personal_data_phone')
      },
      comments: '',
      errors: [],
      isButtonDisabled: false
    }
  },
  methods: {
    submitForm() {
      this.isButtonDisabled = true
      this.errors = []

      if (!this.contact_info.phone) {
        this.errors.push('Пожалуйста, заполните номер телефона')
        this.isButtonDisabled = false
      } else {
        this.sendCallBackRequest()
      }
    },
    async sendCallBackRequest() {
      const phone = this.contact_info.phone.replace(/\+/g, '')
      const cleaned_phone = phone.replace(/ /g, '')
      const contact = {
        'name': this.contact_info.name,
        'phone': cleaned_phone,
      }
      await axios.get(`/api/v1/contacts/?phone=${cleaned_phone}`)
          .then(response => {
            const contact_exists = response.data
            if (contact_exists.length) {
              const callback_data = {
                'contact': contact_exists[0].id,
                'comments': this.comments
              }
              axios.post('/api/v1/callbackrequests/', callback_data)
                  .then(response => {
                    this.callback_modal = false
                    this.thank_you_modal = true
                    localStorage.setItem('personal_data_name', this.contact_info.name);
                    localStorage.setItem('personal_data_phone', this.contact_info.phone);
                    this.isButtonDisabled = false
                  })
            } else {
              axios
                  .post('/api/v1/contacts/', contact)
                  .then(response => {
                    const callback_data = {
                      'contact': response.data.id,
                      'comments': this.comments
                    }
                    axios.post('/api/v1/callbackrequests/', callback_data)
                        .then(response => {
                          this.callback_modal = false
                          this.thank_you_modal = true
                          localStorage.setItem('personal_data_name', this.contact_info.name);
                          localStorage.setItem('personal_data_phone', this.contact_info.phone);
                          this.isButtonDisabled = false
                        })
                  })
                  .catch(error => {
                    this.errors.push('Что-то пошло не так. Попробуйте позже.')
                    this.isButtonDisabled = false
                  })
            }
          })
    }
  },
  created() {
    this.$store.dispatch('site_settings/getSettings')
  },
  computed: {
    ...mapState({
      settings: state => state.site_settings.settings
    }),
  }
}
</script>
<style scoped>
.level {
  margin-bottom: 0 !important;
}

.label-not-required {
  font-weight: normal;
}
</style>