import axios from 'axios'

const state =() => ({
    reasons: [],
})

const mutations = {
    SAVE_REASONS(state, reasons) {
        state.reasons = reasons
    }
}

const actions = {
    getReasons({commit}) {
        axios.get('/api/v1/contact-us-reasons/')
            .then(response => {
                commit('SAVE_REASONS', response.data)
            })
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
}

