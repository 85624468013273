import {createStore} from 'vuex'
import pages from './modules/pages'
import categories from './modules/categories'
import cart from './modules/cart'
import site_settings from './modules/site_settings'
import auth from './modules/auth'
import loading from './modules/loading'
import banners from './modules/banners'
import contact_us_reasons from './modules/contact-us-reasons'

export default createStore({
    modules: {
        pages,
        categories,
        cart,
        site_settings,
        auth,
        loading,
        banners,
        contact_us_reasons
    },
})
