<template>
  <div class="navbar_and_search">
    <div class="">
      <nav class="container navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <router-link to="/" class="navbar-item">
            <img src="../../assets/images/logos/logobig.png" alt="Kanstov.UZ">
          </router-link>
          <a class="navbar-burger" aria-label="menu" aria-expanded="true" data-target="navbar-menu"
            @click="showMobileMenu = !showMobileMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu" id="navbar-menu" :class="{ 'is-active': showMobileMenu }">
          <div class="navbar-start">
            <div class="navbar-item">

            </div>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <div class="navbar-item has-dropdown">
                <a class="navbar-link button is-light" @click="togglePagesDropdown(!showPagesDropdown)">О Компании</a>
                <div class="navbar-dropdown" :style="{ display: showPagesDropdown ? 'block' : 'none' }">
                  <template v-for="page in pages" :key="page.id">
                    <router-link :to="'/company/' + page.slug" class="navbar-item">{{ page.menu_title }}</router-link>
                  </template>
                </div>
              </div>
            </div>
            <div class="navbar-item">
              <div class="navbar-item has-dropdown">
                <a class="navbar-link button is-light"
                  @click="toggleCategoriesDropdown(!showCategoriesDropdown)">Каталог
                  Товаров</a>
                <div class="navbar-dropdown is-expanded" :style="{ display: showCategoriesDropdown ? 'block' : 'none' }">
                  <router-link to="/shop" class="navbar-item">Категории</router-link>
                  <template v-for="category in categories" :key="category.id">
                    <router-link :to="'/shop/' + category.slug" class="navbar-item">{{ category.name }}</router-link>
                  </template>
                </div>
              </div>
            </div>
            <div class="navbar-item">
              <template v-if="isAuthenticated">
                <div class="navbar-item button has-dropdown is-hoverable">
                  <a class="navbar-link button is-light">Аккаунт</a>
                  <div class="navbar-dropdown">
                    <router-link class="navbar-item" to="/user">Мой профиль</router-link>
                    <router-link class="navbar-item" to="/user/orders">Мои заказы</router-link>
                    <a class="navbar-item" @click="logout()">Выйти</a>
                  </div>
                </div>
              </template>
              <template v-else>
                <router-link to="/signin" class="button is-light">Войти</router-link>
              </template>
            </div>
            <div class="navbar-item" v-if="user.is_staff && user.is_active">
              <div class="navbar-item button has-dropdown is-hoverable">
                <a class="navbar-link button is-light">Админка</a>
                <div class="navbar-dropdown">
                  <router-link class="navbar-item" to="/dashboard/orders">Заказы</router-link>
                  <router-link class="navbar-item" to="/dashboard/contacts">Контакты</router-link>
                  <router-link class="navbar-item" to="/dashboard/callbackrequests">Перезвоны</router-link>
                  <router-link class="navbar-item" to="/dashboard/contact-us">Обратные связи</router-link>
                </div>
              </div>
            </div>
            <div class="navbar-item">
              <router-link to="/cart" class="button" :class="{ 'is-primary': cartTotalLength > 0 }">
                <span class="icon"><i class="fas fa-shopping-cart"></i></span>
                <span>({{ cartTotalLength }})</span>
              </router-link>
            </div>
          </div>
        </div>
      </nav>
      <div class="container">
        <div class="control mb-5 mt-5 ml-3 mr-4">
          <form>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input type="text" class="input" placeholder="Поиск по каталогу" name="query" v-autofocus
                  v-model="query">
              </div>
              <div class="control" v-if="query !== ''">
                <router-link :to="'/search/' + query">
                  <button class="button is-outlined">
                    <span class="icon">
                      <i class="fas fa-search"></i>
                    </span>
                  </button>
                </router-link>
              </div>
            </div>
          </form>
          <div v-if="!isAuthenticated">
            <div class="notification mt-3 is-warning" v-if="notification_off === false">
              <button class="delete" @click="toggleNotification"></button>
              <h1 class="title is-2">Бонусы и скидки за регистрацию</h1>
              Только <strong>до 30 декабря</strong>, компания Kanstov.UZ дарит всем подарки и скидки за
              <strong>регистрацию. </strong>
              <router-link to="/signup">Пройдите регистрацию сейчас</router-link>
              и получайте бонусы и скидки на любую покупку.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { toast } from "bulma-toast";
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'NavBar',
  data() {
    return {
      showMobileMenu: false,
      query: '',
      routeChange: false,
      showPagesDropdown: false,
      showCategoriesDropdown: false,
      showSearch: false,
    }
  },
  directives: {
    autofocus: {
      mounted(el) {
        el.focus()
      }
    }
  },
  watch: {
    $route() {
      this.routeChange = true;
      this.showPagesDropdown = false;
      this.showCategoriesDropdown = false;
      this.showMobileMenu = false;
    },
  },
  created() {
    this.$store.dispatch('pages/getPages')
    this.$store.dispatch('categories/getCategories')
  },
  computed: {
    cartTotalLength() {
      let totalLength = 0

      for (let i = 0; i < this.cart.items.length; i++) {
        totalLength += this.cart.items[i].quantity
      }
      return totalLength
    },
    ...mapState({
      pages: state => state.pages.pages,
      categories: state => state.categories.categories,
      isAuthenticated: state => state.auth.isAuthenticated,
      user: state => state.auth.user,
      notification_off: state => state.site_settings.notification_off,
    }),
    ...mapGetters('cart', {
      cart: 'cartItems'
    }),
  },
  methods: {
    toggleNotification() {
      this.$store.commit('site_settings/TOGGLE_NOTIFICATION', true)
    },
    togglePagesDropdown(payload) {
      if (this.showPagesDropdown !== payload) this.routeChange = false;
      if (!this.routeChange) {
        this.showPagesDropdown = payload;
      }
    },
    toggleCategoriesDropdown(payload) {
      if (this.showCategoriesDropdown !== payload) this.routeChange = false;
      if (!this.routeChange) {
        this.showCategoriesDropdown = payload;
      }
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = ""

      localStorage.removeItem("token")
      localStorage.removeItem("user")
      // localStorage.removeItem("userid")

      this.$store.commit('auth/removeToken')

      this.$router.push('/')
      toast({
        message: 'Вы успешно вышли из системы.',
        type: 'is-primary',
        dismissible: true,
        pauseOnHover: true,
        duration: 2000,
        position: 'top-center',
        animate: { in: 'fadeInDown', out: 'zoomOutDown' }
      })
    },
  }
}
</script>
<style scoped>
.navbar-item img {
  max-height: 100%;
}
</style>