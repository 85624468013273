<template>
  <div class="body">
    <TopBar/>
    <div>
      <NavBar/>
      <div style="min-height: 100vh;">
        <div class="is-loading-bar has-text-centered" :class="{'is-loading': $store.state.loading.isLoading}">
          <div class="lds-default">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div>
        </div>
        <router-view v-slot="{Component, route}">
          <component :is="Component" :key="$route.path"></component>
        </router-view>
      </div>
      <FooterComponent/>
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/header/NavBar'
import TopBar from '@/components/header/TopBar'
import FooterComponent from '@/components/FooterComponent'
import axios from 'axios'

export default {
  components: {
    TopBar,
    NavBar,
    FooterComponent
  },
  provide() {
    return {
      formatPrice: this.formatPrice,
      openLoading: this.openLoading,
      closeLoading: this.closeLoading,
      formatDate: this.formatDate,
      formatTime: this.formatTime,
    }
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "  ")
    },
    formatDate(date) {
      return date.split('T')[0]
    },
    formatTime(date) {
      // '2022-08-25T17:24:58.695717+05:00'
      const date_time = date.split('.')[0]
      const time = date_time.substring(date_time.indexOf('T') + 1)
      return time
    },
  },
  beforeCreate() {
    this.$store.commit('cart/initializeCart')
    this.$store.commit('auth/initializeAuth')
    this.$store.dispatch('categories/getCategories')
    this.$store.dispatch('banners/getBanners')
    this.$store.dispatch('contact_us_reasons/getReasons')
    const token = this.$store.state.auth.token

    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }
  },
}
</script>
<style lang="scss">
//@import '../node_modules/bulma';
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap');
html, body {
  overflow-x: hidden;
}

.body {
  //background-color: #f8f9fa;
  font-family: 'Exo 2', sans-serif;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.4s, transform 0.1s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-5%);
}

.breadcrumb {
  font-size: 0.5rem;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.is-loading-bar {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &.is-loading {
    height: 80px;
  }
}
</style>