import axios from 'axios'

const state =() => ({
    pages: [],
})

const mutations = {
    SAVE_PAGES(state, pages) {
        state.pages = pages
    }
}

const actions = {
    getPages({commit}) {
        axios.get('/api/v1/pages/?is_on_menu=true&ordering=priority')
            .then(response => {
                commit('SAVE_PAGES', response.data)
            })
    }
}

const getters = {
    getPageBySlug: (state) => (slug) => {
        return state.pages.find(page => page.slug === slug)
    }
}

export default {
namespaced: true,
    state,
    actions,
    mutations,
    getters
}

