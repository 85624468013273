const state = () => ({
    isAuthenticated: false,
    token: '',
    user:Object,
    orders: Array
})
const mutations = {
    initializeAuth(state) {
        if (localStorage.getItem('token')) {
            state.token = localStorage.getItem('token')
            state.isAuthenticated = true
        } else {
            state.token = ''
            state.isAuthenticated = false
        }
        if (localStorage.getItem('user')) {
            state.user = JSON.parse(localStorage.getItem('user'))
        } else {
            state.user = ''
        }
    },
    setToken(state, token) {
        state.token = token
        state.isAuthenticated = true
        localStorage.setItem("token", token)
    },
    removeToken(state) {
        state.token = ''
        state.isAuthenticated = false
        state.user = ''
    },
    setUserDetails(state, user) {
        state.user = user
        localStorage.setItem('user', JSON.stringify(state.user))
    },
}
const getters = {
    userDetails(state) {
        return state.user
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
