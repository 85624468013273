<template>
  <div class="home">

    <div v-if="products.length">
      <ProductBox :products="products" :title="`Популярные товары`"/>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import {defineAsyncComponent} from 'vue'
import {mapState} from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      products: [],
    }
  },
  components: {
    BannerComponent: defineAsyncComponent(() =>
        import('@/components/BannerComponent')
    ),
    ProductBox: defineAsyncComponent(() =>
        import('@/components/ProductBox')
    )

  },
  mounted() {
    this.getMostViewedProducts()
    document.title = 'Канцтовары Онлайн | Хоз Товары | Электротехника | Бытовая Техника | Kanstov.UZ'
  },
  methods: {
    async getMostViewedProducts() {
      this.$store.commit('loading/setIsLoading', true)
      await axios
          .get('/api/v1/products/?ordering=-count_viewed')
          .then(response => {
            this.products = response.data.results
          })
          .catch(error => {
          })
      this.$store.commit('loading/setIsLoading', false)
    },
  },
  computed: {
    ...mapState({
      banners: state => state.banners.banners,
    }),
  }
}
</script>
<style>
.columns {
  max-width: 100%;
}

.product-collection-container {
  width: 88vw;
  margin: 0 auto;
}

.product-collection {
  padding: 3.2rem 0;
}

.product-collection-wrapper {
  padding: 3.2rem 0;
}

.product-col-left {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url("../assets/images/misc/delete_later/fashion-img-1.jpg") center/cover no-repeat;
}

.product-col-r-top {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url("../assets/images/misc/delete_later/fashion-img-2.png") center/cover no-repeat;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50vh;
  padding: 2rem 1.5rem 3.2rem;
  margin: 5px;
}

.product-col-r-bottom > div:first-child {
  background: #eaa001;
}

.product-col-r-bottom > div:last-child {
  background: #0090ff;
}

.product-col-content {
  text-align: center;
  color: white;
}

.product-collection .text-light {
  opacity: 1;
  font-size: 0.8;
  font-weight: 400;
  line-height: 1.7;
}

.btn-dark {
  background: black;
  color: white;
  outline: 0;
  border-radius: 25px;
  padding: 0.7rem 1rem;
  border: 0;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.6s ease;
  font-size: 1rem;
  font-family: inherit;
}

.btn-dark:hover {
  background: var(--carribean-green);
}


/* Media Queries */
@media screen and (min-width: 992px) {
  .product-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .product-col-r-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .product-items {
    grid-template-columns: repeat(3, 1fr);
  }

  .product {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .products .text-light {
    width: 50%;
  }
}

@media screen and (min-width: 1336px) {
  .product-items {
    grid-template-columns: repeat(4, 1fr);
  }

  .product-collection-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .flex {
    height: 60vh;
  }

  .product-col-left {
    height: 121.5vh;
  }
}
</style>